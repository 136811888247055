import React, { memo, useMemo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import { useTranslation } from "react-i18next";
import Card from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { CompareContainer } from "@/game-deadlock/components/MatchLaneCompare.style.jsx";
import { LANE_COLORS } from "@/game-deadlock/constants/match-lanes.mjs";
import { aggPlayersStats } from "@/game-deadlock/utils/agg-player-stats.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import { useHeroesList } from "@/game-deadlock/utils/use-heroes-list.mjs";
import Arrow from "@/inline-assets/Blitz_Chevron_Right.svg";
import Stopwatch from "@/inline-assets/stopwatch.svg";
import { classNames } from "@/util/class-names.mjs";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const STATS = {
  kills: {
    label: ["common:stats.kills", "Kills"],
    format: {},
  },
  souls: {
    label: ["common:stats.souls", "Souls"],
    format: {},
  },
  creep_kills: {
    label: ["common:stats.creepKills", "Last Hits"],
    format: {},
  },
  denies: {
    label: ["common:stats.denies", "Denies"],
    format: {},
  },
  damage: {
    label: ["common:stats.damage", "Damage"],
    format: {},
  },
  obj_damage: {
    label: ["common:stats.objDamage", "Objective Damage"],
    format: {},
  },
  headshot_percent: {
    label: ["common:stats.headshotPercent", "Headshot %"],
    format: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  hit_percent: {
    label: ["common:stats.shotsHitPercent", "Shots Hit %"],
    format: {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    },
  },
  ability_points: {
    label: ["common:stats.abilityPoints", "Ability Points"],
    format: {},
  },
};

function LaneCompare({
  matchId,
  steamId,
  className = "",
}: {
  matchId: string;
  steamId: string;
  className?: string;
}) {
  const { t } = useTranslation();
  const {
    deadlock: { matches },
  } = useSnapshot(readState);
  const match = matches[matchId];

  const players = match?.players || [];

  const localPlayer = players.find((p) => steamId === `${p.account_id}`);
  const localPlayerTeam = localPlayer?.team || 0;
  const teammates = players
    .filter((p) => p.team === localPlayerTeam)
    .sort((a, b) => a.assigned_lane - b.assigned_lane);
  const opponents = players
    .filter((p) => p.team !== localPlayerTeam)
    .sort((a, b) => a.assigned_lane - b.assigned_lane);

  const defaultSelectedPlayers = localPlayer
    ? players
        .filter((p) => p.assigned_lane === localPlayer.assigned_lane)
        .map((p) => p.account_id)
    : teammates.filter((p) => p.assigned_lane === 1).map((p) => p.account_id);

  const [selectedPlayers, setSelectedPlayers] = useState<number[]>(
    defaultSelectedPlayers,
  );
  const [selectedTimeIndex, setSelectedTimeIndex] = useState<number>(2);

  const teammatesStats = aggPlayersStats(
    selectedTimeIndex,
    teammates.filter((p) => selectedPlayers.includes(p.account_id)),
  );
  const opponentsStats = aggPlayersStats(
    selectedTimeIndex,
    opponents.filter((p) => selectedPlayers.includes(p.account_id)),
  );

  return (
    <Card classNameOuter={className} className={CompareContainer()} padding="0">
      <header>
        <div className="teams">
          <div className="team">
            {teammates.map((p) => {
              return (
                <PlayerSelect
                  key={p.account_id}
                  selectedPlayers={selectedPlayers}
                  assignedLane={p.assigned_lane}
                  heroId={p.hero_id}
                  accountId={p.account_id}
                  onClick={() => {
                    if (selectedPlayers.includes(p.account_id)) {
                      setSelectedPlayers(
                        selectedPlayers.filter((id) => id !== p.account_id),
                      );
                    } else {
                      setSelectedPlayers([...selectedPlayers, p.account_id]);
                    }
                  }}
                />
              );
            })}
          </div>
          <span className="vs type-title--bold shade2">
            {t("common:vs", "vs")}
          </span>
          <div className="team">
            {opponents.map((p) => {
              return (
                <PlayerSelect
                  key={p.account_id}
                  selectedPlayers={selectedPlayers}
                  assignedLane={p.assigned_lane}
                  heroId={p.hero_id}
                  accountId={p.account_id}
                  onClick={() => {
                    if (selectedPlayers.includes(p.account_id)) {
                      setSelectedPlayers(
                        selectedPlayers.filter((id) => id !== p.account_id),
                      );
                    } else {
                      setSelectedPlayers([...selectedPlayers, p.account_id]);
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="times">
          {players[0].stats.map((p, i) => {
            const isEOG = i === teammates[0].stats.length - 1;
            const timeDisplay = isEOG
              ? t("common.eog", "EOG")
              : p.time_stamp_s / 60;

            return (
              <button
                key={p.time_stamp_s}
                onClick={() => setSelectedTimeIndex(i)}
                className="time type-caption--bold"
                data-selected={selectedTimeIndex === i}
                data-tip={
                  isEOG
                    ? t(
                        "common:viewStatsAtEndOfGame",
                        "View Stats at the end of the game",
                      )
                    : t(
                        "common:viewStatsAtMinute",
                        "View Stats at {{mins}} minutes",
                        {
                          mins: p.time_stamp_s / 60,
                        },
                      )
                }
                style={{ "--suffix": `" ${t("common:mins", "mins")}"` }}
              >
                <Stopwatch width="14" height="14" />
                <span>{timeDisplay}</span>
              </button>
            );
          })}
        </div>
      </header>
      <div className="stats">
        {Object.keys(STATS).map((stat) => {
          const diff = teammatesStats[stat] - opponentsStats[stat];
          const percentDiff =
            teammatesStats[stat] /
            (teammatesStats[stat] + opponentsStats[stat]);
          const better = percentDiff > 0.5;
          const diffColor = better && "var(--turq)";

          return (
            <div key={stat} className="stat" data-better={better}>
              <div
                className="stat-fill"
                style={{
                  "--fill":
                    teammatesStats[stat] /
                    (teammatesStats[stat] + opponentsStats[stat]),
                }}
              />
              <span
                {...classNames(
                  "stat-value",
                  better ? "type-title--bold" : "type-title--semi",
                )}
                style={{ color: diffColor }}
              >
                {diff > 0 && (
                  <span className="type-title--semi" style={{ opacity: 0.5 }}>
                    (+{diff.toLocaleString(getLocale(), STATS[stat].format)}
                    )&nbsp;&nbsp;
                  </span>
                )}
                {teammatesStats[stat].toLocaleString(
                  getLocale(),
                  STATS[stat].format,
                )}
              </span>
              <Arrow
                className="arrow"
                style={{
                  transform: "rotate(180deg)",
                  opacity: diff > 0 ? 1 : 0,
                  color: diffColor,
                }}
                width="24"
                height="24"
              />
              <span className="stat-name type-overline">
                {t(...STATS[stat].label) || stat}
              </span>
              <Arrow
                className="arrow"
                width="24"
                height="24"
                style={{
                  opacity: !better && diff !== 0 ? 1 : 0,
                  color: diff < 0 && "var(--perf-neg2)",
                }}
              />
              <span
                {...classNames(
                  "stat-value",
                  better ? "type-title--bold" : "type-title--semi",
                )}
                style={{ color: diff < 0 && "var(--perf-neg2)" }}
              >
                {opponentsStats[stat].toLocaleString(
                  getLocale(),
                  STATS[stat].format,
                )}
                {diff < 0 && (
                  <span className="type-title--semi" style={{ opacity: 0.5 }}>
                    &nbsp;&nbsp;(
                    {diff.toLocaleString(getLocale(), STATS[stat].format)})
                  </span>
                )}
              </span>
            </div>
          );
        })}
      </div>
    </Card>
  );
}

export default LaneCompare;

function Player({
  selectedPlayers,
  onClick = () => {},
  accountId,
  heroId,
  assignedLane,
}: {
  selectedPlayers: number[];
  onClick: () => void;
  accountId: number;
  heroId: number;
  assignedLane: number;
}) {
  const { t } = useTranslation();
  const {
    deadlock: { steam },
  } = useSnapshot(readState);

  const { dict: heroesDict } = useHeroesList();

  const heroStaticData = heroesDict[heroId];

  const steamName = steam[accountId]?.name;

  const Tooltip = useMemo(() => {
    return ReactDOMServer.renderToStaticMarkup(
      <div style={{ display: "grid" }}>
        {steamName && <span className="type-overline shade1">{steamName}</span>}
        <span className="type-title--bold">
          {!selectedPlayers.includes(accountId) && heroStaticData
            ? t("common:addHeroName", "+ Add {{heroName}}", {
                heroName: heroStaticData.heroName,
              })
            : heroStaticData
              ? t("common:removeHeroName", "- Remove {{heroName}}", {
                  heroName: heroStaticData.heroName,
                })
              : null}
        </span>
      </div>,
    );
  }, [accountId, steamName, selectedPlayers, heroStaticData, t]);

  return (
    <div
      className="player"
      data-selected={selectedPlayers.includes(accountId)}
      style={{
        "--lane-color": LANE_COLORS[assignedLane] || "var(--shade2)",
      }}
      onClick={onClick}
      data-tip={Tooltip}
    >
      <div className="line"></div>
      <div className="hero-portrait">
        <img src={Assets.getHeroPortrait(heroId)} width="48" height="48" />
      </div>
    </div>
  );
}

const PlayerSelect = memo(Player);
