import { css } from "goober";

export const Tabs = () => css`
  .tabs-header {
    display: flex;
    justify-content: space-between;
    padding-inline: var(--sp-2);
    background: var(--shade8);
    border-bottom: 1px solid var(--shade6);
  }
  .tabs-list {
    display: flex;

    @container card-contents (max-inline-size: 300px) {
      & {
        flex-direction: column;
        width: 100%;
      }
    }
  }
  .tabs-tab {
    position: relative;
    padding: var(--sp-4) var(--sp-2);
    color: var(--shade2);
    background: transparent;
    cursor: pointer;

    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: var(--sp-6);
      height: 3px;
      border-top-left-radius: var(--br-sm);
      border-top-right-radius: var(--br-sm);
      translate: -50% 0;
    }

    &.active {
      color: var(--shade0);

      &::before {
        background: var(--primary);
      }
    }

    @container card-contents (max-inline-size: 300px) {
      & {
        padding: var(--sp-2);
      }
    }
  }

  .tabs-content {
    padding: var(--sp-2);
  }
`;
