import React from "react";

import { readState } from "@/__main__/app-state.mjs";
import getPlayer from "@/game-deadlock/utils/get-player.mjs";
import IncomeCompare from "@/shared/IncomeCompare.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const GoldGraph = ({ steamId, matchId }) => {
  const {
    deadlock: { matches },
  } = useSnapshot(readState);

  const match = matches[matchId];
  const player = getPlayer(steamId, match);
  const teammates = match.players.filter((p) => p.team === player.team);
  const opponents = match.players.filter((p) => p.team !== player.team);

  const soulsDiff = player.stats.map((p, i) => {
    const teamNetWorth = teammates.reduce(
      (acc, p) => acc + p.stats[i].net_worth,
      0,
    );
    const opponentNetWorth = opponents.reduce(
      (acc, p) => acc + p.stats[i].net_worth,
      0,
    );

    return {
      diff: teamNetWorth - opponentNetWorth,
      time: p.time_stamp_s / 60,
    };
  });

  return <IncomeCompare data={soulsDiff} />;
};

export default GoldGraph;
