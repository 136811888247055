import { css } from "goober";

export const CompareContainer = () => css`
  header {
    display: grid;
    gap: var(--sp-2);
    padding: var(--sp-2);
    background: var(--shade7);
    border-bottom: 1px solid var(--card-inset-border);

    @container content-card (inline-size < 700px) {
      padding: var(--sp-4);
    }
  }

  .teams {
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    justify-content: space-between;

    @container content-card (inline-size < 600px) {
      flex-direction: column;
      gap: 0;
    }
  }
  .team {
    display: flex;
    gap: var(--sp-1);

    @container content-card (inline-size < 600px) {
      width: 100;
    }
  }
  .player {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.5;
    filter: grayscale(0.5);
    cursor: pointer;

    @container content-card (inline-size < 600px) {
      flex: 1;
    }

    &:hover {
      opacity: 0.75;
      filter: none;

      .hero-portrait {
        background: color-mix(in hsl, var(--lane-color), transparent 75%);
        filter: none;
        opacity: 1;
      }
      .line {
        opacity: 1;
      }
    }
    &[data-selected="true"] {
      opacity: 1;
      filter: none;

      .hero-portrait {
        background: color-mix(in hsl, var(--lane-color), transparent 75%);
        filter: none;
        opacity: 1;
      }
      .line {
        opacity: 1;
      }
      .hero-portrait {
        border-color: var(--lane-color);
      }
    }

    .line {
      position: relative;
      width: 6px;
      height: 6px;
      background: var(--lane-color);
      rotate: 45deg;
      margin-bottom: -2px;
      box-shadow: 0 0 0 2px var(--shade9);
      z-index: 1;
      opacity: 0;
      transition: opacity var(--transition);
    }
    .hero-portrait {
      width: var(--sp-12);
      height: auto;
      aspect-ratio: 48 / 64;
      border: 1px solid var(--shade3);
      border-radius: var(--br-sm);
      overflow: clip;
      filter: grayscale(0.5);
      opacity: 0.75;

      @container content-card (inline-size < 800px) {
        width: var(--sp-10);
        height: var(--sp-10);

        img {
          translate: 0 -10%;
        }
      }

      @container content-card (inline-size < 600px) {
        width: 100%;

        img {
          translate: 0 -18%;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  .vs {
    @container content-card (inline-size < 600px) {
      display: none;
    }
  }

  .times {
    display: flex;
    gap: var(--sp-0_5);

    @container content-card (inline-size < 600px) {
      flex-wrap: wrap;
    }
  }
  .time {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sp-1);
    padding: var(--sp-1) var(--sp-1_5);
    background: var(--shade4-25);
    color: var(--shade2);
    border-radius: var(--br-sm);
    cursor: pointer;
    transition:
      background var(--transition),
      color var(--transition);

    &:hover {
      background: var(--shade5);
      color: var(--shade1);
    }
    &[data-selected="true"] {
      background: var(--shade5);
      color: var(--shade0);
      box-shadow: inset 0 0 0 1px var(--shade2-50);
    }

    &:not(:last-child) span::after {
      content: var(--suffix);

      @container content-card (inline-size < 850px) {
        content: none;
      }
    }

    svg {
      opacity: 0.5;

      @container content-card (inline-size < 850px) {
        display: none;
      }
    }

    span {
      line-height: 1;
      padding-block-start: var(--sp-0_5);
    }

    @container content-card (inline-size < 600px) {
      flex: unset;
    }
  }

  .stats {
    display: grid;
    gap: var(--sp-1);
    padding: var(--sp-2);

    @container content-card (inline-size < 700px) {
      padding: var(--sp-4);
    }
  }
  .stat {
    --radii: var(--br);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--sp-2);
    padding: var(--sp-2) var(--sp-6);
    color: var(--shade1);
    background: var(--shade6-15);
    border-radius: var(--radii);

    > * {
      position: relative;
    }
  }
  .stat-name {
    width: 14cqw;
    white-space: nowrap;
    text-align: center;
    overflow: clip;
    text-overflow: ellipsis;
  }
  .stat-value {
    flex: 1;
    white-space: nowrap;
    overflow: clip;
    text-overflow: ellipsis;
    transition: color var(--transition);

    &:first-of-type {
      text-align: right;
    }

    span {
      @container content-card (inline-size < 550px) {
        display: none;
      }
    }
  }
  .stat-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(var(--fill) * 100%);
    height: 100%;
    background: linear-gradient(to right, transparent, var(--shade5-25));
    border-radius: var(--radii);
    transition:
      width var(--transition),
      opacity var(--transition);
  }
  .arrow {
    transition: opacity var(--transition);

    &:first-of-type {
      @container content-card (inline-size > 600px) {
        margin-inline-start: var(--sp-8);
      }
    }
    &:last-of-type {
      @container content-card (inline-size > 600px) {
        margin-inline-end: var(--sp-8);
      }
    }
  }
`;
