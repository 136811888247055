import type { MatchPlayer } from "@/game-deadlock/models/match.mjs";
import { calcRate } from "@/util/helpers.mjs";

/**
 * Aggregate player stats for a given index (time in 3min intervals).
 * @param index - The index of the stats to aggregate.
 * @param team - The team (array of players) to aggregate stats for.
 * @returns The aggregated stats.
 */
export function aggPlayersStats(index: number = -1, team: MatchPlayer[] = []) {
  const agg = team.reduce(
    (acc, p) => {
      const stats = p.stats.at(index);

      acc.kills += stats.kills || 0;
      acc.souls += stats.net_worth || 0;
      acc.creep_kills += stats.creep_kills || 0;
      acc.denies += stats.denies || 0;
      acc.damage += stats.player_damage || 0;
      acc.obj_damage += stats.boss_damage || 0;
      acc.headshot_percent +=
        stats.hero_bullets_hit_crit / stats.hero_bullets_hit || 0;
      acc.hit_percent +=
        stats.shots_hit / (stats.shots_hit + stats.shots_missed) || 0;
      acc.ability_points += stats.ability_points || 0;

      return acc;
    },
    {
      kills: 0,
      souls: 0,
      creep_kills: 0,
      denies: 0,
      damage: 0,
      obj_damage: 0,
      headshot_percent: 0,
      hit_percent: 0,
      ability_points: 0,
    },
  );

  agg.headshot_percent = calcRate(agg.headshot_percent, team.length);
  agg.hit_percent = calcRate(agg.hit_percent, team.length);

  return agg;
}
